<template>
  <div>
    <CCol col="12">
      <h4>My Assets</h4>
    </CCol>
    <CRow class="mt-3">
<!--      {{itemNft}}-->
      <CCol sm="6" md="4" lg="4" xl="3" v-for="(item, index) in itemNft" v-bind:key="index">
<!--        {{item}}-->
        <ItemMarkets :item="item" @update:value="onLike" @infomation:value="onInfomation"/>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'
import ItemMarkets from "@/project/views/util/ItemMarkets";

export default {

  name: 'Assets',
  components: { ItemMarkets},
  data: function () {
    return {
      categorie : ""

    }
  },


  mounted() {
    this.$store.dispatch('metamask/onMetaMaskAddress')
  },

  created() {

  },

  beforeDestroy() {

  },

  methods: {

    onInfomation(value){
      var obj = {};
      obj.id = value._id
      this.$store.dispatch("gateway/onCollectionByNfts", obj)
    },

    onLike(value){

    },
    onInit(){
      this.$store.dispatch("nextArt/onNFTAssets")
    }
  },

  computed: {
    ...mapGetters({
      profile:'gateway/objProfile',
      itemNft:'nextArt/objNFTAssets',
      nfts:'gateway/objCollectionId'
    })
  },

  watch: {
    profile(value){
      // console.log(value)
      this.onInit();
    },
    nfts(value){
      this.$router.push({name:'Assets',  query: {id:value.collectionId,assets:value.nftsId} })
    }
  }
}
</script>
